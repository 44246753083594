// import html2pdf from 'html2pdf.js';
// import ChargerzoneLogo from 'assets/images/chargezone-logo.png';

const generateContentHTML = async (rowData) => {
  let damageItemsHTML = '';
  if (rowData?.anyDamage === 'yes') {
    damageItemsHTML = `
      <span class="tablelabel">List of Damaged / Missing Items</span>
      <p class="tablep">${rowData?.missingItems || '-'}</p>
    `;
  }
  let storagefromto = '';
  if (rowData?.storage === 'yes') {
    storagefromto = `<span class="tablelabel">From - To</span>
    <p class="tablep">${rowData?.storageFrom || '-'} - ${rowData?.storageTo || '-'}</p>`;
  }
  let chargerCheck = '';
  if (rowData?.chargerInstallationDetails?.chargerCheck === 'yes') {
    chargerCheck = `<tr>
   <td class="tabletd">Drawing Number</td>
   <td class="tabletdp" style="text-transform:uppercase;">${rowData?.chargerInstallationDetails?.drawingNumber || '-'}</td>
   </tr>`;
  }
  let noReason = '';
  if (rowData?.chargerInstallationDetails?.bollards === 'no') {
    noReason = `<tr>
    <td class="tabletd">If no, mention reason</td>
    <td class="tabletdp" style="text-transform:uppercase;">${rowData?.chargerInstallationDetails?.noReason || '-'}</td>
    </tr>`;
  }
  let staticIpsHTML = '';
  if (rowData?.internetDetails?.ips === 'static') {
    if (rowData?.internetDetails?.staticIps && rowData?.internetDetails.staticIps.length > 0) {
      staticIpsHTML = `<strong class="tablelabel">List of Static IPs & Configuration Details With Charger</strong><br/>`;
      staticIpsHTML += rowData.internetDetails.staticIps.map((ip) => `<span style="font-weight:600">${ip}</span>`).join(', ');
    } else {
      staticIpsHTML = '<span>-</span>';
    }
  }
  // let golivedata = '';
  // if (rowData?.status == 'approved') {
  //   golivedata = `<div class="part-2" class="colStyle">
  //     <strong class="tablelabel">Go Live Date</strong>
  //     <p class="tablep">${rowData?.submitDate || '-'}</p>
  //   </div>`;
  // }
  const getFormattedHTML = () => `
      <!DOCTYPE html>
      <html>
        <head>
        <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap" rel="stylesheet">
          <style>
           @page {
      size: a4;
      margin: 1.5em;
   }table, td, th {
  border: 1px solid #C0C0C0;
}

table {
  width: 100%;
  border-collapse: collapse;
}
            .logoStyle{height:50px}
            .titleStyle{font-weight:900;color:#000;font-family: Mulish;text-transform:uppercase;font-size:16px;margin-top:0.1em;margin-bottom:0px;}
            .colStyle {flex:50%;flex-basis:50%;}
            .colstyle1 {flex:33.33%;flex-basis:33.33%;}
            .tableStyle,.div-2 {display:flex;flex-wrap:wrap;margin-top:0.1em;place-content: space-between;}
            .headertable {font-family: Mulish;font-size:10px;white-space: nowrap;font-weight:900;border-radius:0px}
            .headertablecol {font-family: Mulish;font-size:10px;font-weight:900;border-radius:0px}
            .tablelabel {font-weight:900;font-family: Mulish;text-transform:capitalize;font-size:12px;}
            .tablep {font-family: Mulish;margin-bottom:0.3em;font-size:12px;color:#000;margin-top: 0.5em;}
            .tableheadeing {font-weight:900;color:#1abc9c;text-transform:uppercase;font-family: Mulish;margin-bottom:0px;margin-top: 0.2em;}
            .tabletd {font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px}
            .tabletdp {font-family: Mulish;border-radius:0px;}
            .tablepara {font-family: Mulish;font-size:12px;border-radius:0px;color:#000;}
            .tablecenter {font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px;}
            .tablecenterp {text-align:center;font-family: Mulish;border-radius:0px}
            .newp {font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px}
            .part-1 {flex: 50%;}
            .part-2 {flex: 50%;}
            .tableStyle .part-1{flex: 1;}
           
          </style>
        </head>
        <body>
<table cellpadding="8" style="font-family: Mulish;width: 100%;border-radius:0px;border-spacing: 0">
  <tr>
    <td colspan="3" rowspan="3" style="border-radius:0px;text-align:center;"><img src='https://cdn.prod.website-files.com/65845678d690aaaf59a85f8d/6586772e17c12763f89e75f7_Group%2010809.svg' class="logoStyle"/></td>
    <td rowspan="3" style="font-weight:900;text-align:center;font-family: Mulish;font-size:12px;border-radius:0px">Installation &amp; Commissioning Report</td>
    <td style="font-family: Mulish;font-size:10px;font-weight:900;border-radius:0px">Published On: 01-APR-2024 </td>
  </tr>
  <tr >
    <td style="font-family:Mulish;font-size:10px;font-weight:900;border-radius:0px">Format No: F03 NET Installation &amp; Commissioning Report_V1.2</td>
  </tr>
  <tr >
    <td style="font-family:Mulish;font-size:10px;font-weight:900;border-radius:0px">Procedure No: TCZ/NET/I&C/001</td>
  </tr>
  <tr>
    <td class="headertable">Prepared By</td>
    <td class="headertable">Reviewed By</td>
    <td class="headertable">Approved By</td>
    <td rowspan="4" style="text-align:center;font-family: Mulish;font-size:10px;font-weight:900;border-radius:0px">Department: EVSE Network <br/>Administration</td>
    <td class="headertablecol">Document No: ${rowData?.documentNo || '-'}</td>
  </tr>
  <tr>
    <td class="headertable">Jatindra Suthar</td>
    <td class="headertable">Kinnal Desai</td>
    <td class="headertable">Kinnari Hariyani</td>
    <td class="headertablecol">Date:${rowData?.dateOfCommission || '__/__/____'}</td>
  </tr>
</table>
<div style="margin:1em 0em 0em;"></div>
<h2 class="titleStyle">Pre-Installation & Commissioning</h2>
<div class="tableStyle">
  <div class="part-1 colstyle1 "  style="margin-bottom:0.3em;">
    <h4 class="tableheadeing">Submitted By :</h4>
    <p class="tablep"><strong class="tablelabel">Name: </strong>${rowData?.submittedBy?.name || '-'} ${rowData?.submittedBy?.lastName || '-'}
    </p>
    <p class="tablep"><strong class="tablelabel">Email ID: </strong>${rowData?.submittedBy?.email || '-'}
    </p>
    <p class="tablep"><strong class="tablelabel">Contact No: </strong>${rowData?.submittedBy?.phone || '-'}
    </p>
    <p class="tablep"><strong class="tablelabel">role: </strong>${rowData?.submittedBy?.role || '-'}
    </p>
  </div>
  <div class="part-1 colstyle1"  style="margin-bottom:0.3em;">
    <h4 class="tableheadeing">Reporting To :</h4>
    <p class="tablep"><strong class="tablelabel">Name: </strong>${rowData?.reportingTo?.name || '-'} ${rowData?.reportingTo?.lastName || '-'}
    </p>
    <p class="tablep"><strong class="tablelabel">Email ID: </strong>${rowData?.reportingTo?.email || '-'}
    </p>
    <p class="tablep"><strong class="tablelabel">Role: </strong>${rowData?.reportingTo?.role || '-'}
    </p>
  </div>
  <div class="part-1 colstyle1"  style="margin-bottom:0.3em;">
    <h4 style="font-weight:900;color:#1abc9c;text-transform:uppercase;font-family: Mulish;margin-bottom:0px;">Status : ${rowData?.status || '-'}</h4>
  </div>
</div>
<h4 class="tableheadeing">General Details</h4>
<div style="margin:0.1em 0em;background:#1abc9c;height:1px;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Station Name (As per CMS/App)</strong>
    <p class="tablep">${rowData?.stationName || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Project Type </strong>
    <p class="tablep">${rowData?.projectType || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Document Number</strong>
    <p class="tablep">${rowData?.documentNo || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Project / Client Name</strong>
    <p class="tablep">${rowData?.projectName || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Charger Serial Number</strong>
    <p class="tablep">${rowData?.chargerSerialNo || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Charger OEM/Power Rating/No of Connectors</strong>
    <p class="tablep">${rowData?.chargerPowerRating || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Charger ID / OCPP ID</strong>
    <p class="tablep">${rowData?.chargerId || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Location / Address</strong>
    <p class="tablep">${rowData?.locationAddress || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Firmware Version</strong>
    <p class="tablep">${rowData?.chargerFirmwareVersion || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Date of Commissioning</strong>
    <p class="tablep">${rowData?.dateOfCommission || '-'}</p>
  </div>
    <div class="part-2" class="colStyle">
    <strong class="tablelabel">Go Live Date</strong>
    <p class="tablep">${rowData?.goLiveDate || '-'}</p>
  </div>
</div>
<h4 class="tableheadeing">ACDB Details</h4>
<div style="margin:0.1em 0em;background:#1abc9c;height:1px;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Manufacturer Name</strong>
    <p class="tablep">${rowData?.manufacturerName || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">ACDB Serial No.</strong>
    <p class="tablep">${rowData?.ACDBSerialNo || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Connected Chargers (In case of multiple chargers connected to one ACDB)</strong>
    <p class="tablep">${rowData?.connectedChargers || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Installation Date</strong>
    <p class="tablep">${rowData?.installationDate || '-'}</p>
  </div>
</div>
<h4 class="tableheadeing">Transportation Details</h4>
<div style="margin:0.1em 0em;background:#1abc9c;height:1px;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Charger Arrival Date at Site</strong>
    <p class="tablep">${rowData?.chargerArrivalDate || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">ACDB Arrival Date at Site</strong>
    <p class="tablep">${rowData?.ACDBArrivalDate || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Transporter Name</strong>
    <p class="tablep">${rowData?.nameOfTransporter || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Consignee Name</strong>
    <p class="tablep">${rowData?.consigneeName || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Consignee Contact Number</strong>
    <p class="tablep">${rowData?.consigneeContact || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Any Damaged / Missing Item?</strong>
    <p class="tablep">${rowData?.anyDamage}</p>
    ${damageItemsHTML}
  </div>
</div>
<h4 class="tableheadeing">Storage</h4>
<div style="margin:0.1em 0em;background:#1abc9c;height:1px;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Storage more than one week?</strong>
    <p class="tablep">${rowData?.storage || '-'}</p>
    ${storagefromto}  
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Person Responsible For Storage Name
    </strong>
    <p class="tablep">${rowData?.storagePerson || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">
    Person Responsible For Storage Number</strong>
    <p class="tablep">${rowData?.storagePersonContact || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Storage Place </strong>
    <p class="tablep">${rowData?.storagePlace || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">
    Storage Address</strong>
    <p class="tablep">${rowData?.storageAddress || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Plastic Covering</strong>
    <p class="tablep">${rowData?.plasticCovering || '-'}</p>
  </div>
</div>
<div style="page-break-before:always">&nbsp;</div> 
<h2 class="titleStyle">Post-Installation </h2>
<h4 class="tableheadeing">Electricity Connection Details</h4>
<div style="margin:0.1em 0em;background:#1abc9c;height:1px;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Electricity Connection Ownership Name</strong>
    <p class="tablep">${rowData?.electricityConnectionName || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Partner/Owner Contact Number </strong>
    <p class="tablep">${rowData?.partnerContactNumber || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Application Number</strong>
    <p class="tablep">${rowData?.applicationNumber || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Name As per Electricity Bill</strong>
    <p class="tablep">${rowData?.consumerName || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Number As per Electricity Bill</strong>
    <p class="tablep">${rowData?.consumerContact || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Responsible Class A Contractor Name</strong>
    <p class="tablep">${rowData?.contractorName || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Responsible Class A Contractor Contact Number</strong>
    <p class="tablep">${rowData?.contractorContact || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Sanctioned Load</strong>
    <p class="tablep">${rowData?.sanctionedLoad || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Electricity Bill Payer</strong>
    <p class="tablep">${rowData?.electricityBillPayer || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Power Availability Date</strong>
    <p class="tablep">${rowData?.powerAvailabilityDate || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Existing Connection</strong>
    <p class="tablep">${rowData?.existconnection || '-'}</p>
  </div>
</div>
<h4 class="tableheadeing">Charger Installation</h4>
<div style="margin:0.1em 0em 0.3em;background:#1abc9c;height:1px;"></div>
<table cellpadding="5" style="font-family: Mulish;width: 100%;border-radius:0px;">
  <tr>
    <td class="tabletd">Charger Foundation is check as per the GA drawing of OEM</td>
    <td class="tablepara" style="text-transform:uppercase;">${rowData?.chargerInstallationDetails?.chargerCheck || '-'}</td>
  </tr>
  ${chargerCheck}
  <tr>
    <td class="tabletd">Charger Panel Level (zero - zero)</td>
    <td class="tablepara" style="text-transform:uppercase;">${rowData?.chargerInstallationDetails?.chargerPanelLevel || '-'}</td>
  </tr>
  <tr>
    <td class="tabletd">Charger Anchor fastener is mounted as per the instructions</td>
    <td class="tablepara" style="text-transform:uppercase;">${rowData?.chargerInstallationDetails?.chargerAnchor || '-'}</td>
  </tr>
  <tr>
    <td class="tabletd">Availability/ Installation of Fire Extinguisher</td>
    <td class="tablepara" style="text-transform:uppercase;">${rowData?.chargerInstallationDetails?.availability || '-'}</td>
  </tr>
  <tr>
    <td class="tabletd">Installation of instruction Boards</td>
    <td class="tablepara" style="text-transform:uppercase;">${rowData?.chargerInstallationDetails?.installation || '-'}</td>
  </tr>
  <tr>
    <td class="tabletd">Timer Set for Auxiliary Lights/LEDs</td>
    <td class="tablepara" style="text-transform:uppercase;">${rowData?.chargerInstallationDetails?.timer || '-'}</td>
  </tr>
  <tr>
    <td class="tabletd">Installation of bollards/vehicle stopper near to charger</td>
    <td class="tablepara" style="text-transform:uppercase;" >${rowData?.chargerInstallationDetails?.bollards || '-'}</td>
  </tr>
   <tr>
    <td class="tabletd">Station work completion status</td>
    <td class="tablepara" style="text-transform:uppercase;" >${rowData?.chargerInstallationDetails?.station_status || '-'}</td>
  </tr>
  ${noReason}
</table>
<div style="margin:0.1em 0em 0.3em;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Other Comments/Observations</strong>
    <p class="tablep">${rowData?.chargerInstallationDetails?.observations || '-'}</p>
  </div>
</div>
<h4 class="tableheadeing">Circuit Measurements</h4>
<div style="margin:0.1em 0em 0.3em;background:#1abc9c;height:1px;"></div>
<table cellpadding="5" style="font-family: Mulish;width: 100%;border-radius:0px;">
  <thead>
    <tr>
      <th colSpan="1" rowSpan="3" scope="colgroup" class="tablecenter"  style="text-align:center;">
        Phase/Line
      </th>
      <th colSpan="2" scope="colgroup" class="tablecenter"  style="text-align:center;">
        Main Panel
      </th>
      <th colSpan="2" scope="colgroup" class="tablecenter"  style="text-align:center;">
        Charger ACDB Panel
      </th>
      <th colSpan="2" scope="colgroup" class="tablecenter"  style="text-align:center;">
        Charger I/P
      </th>
    </tr>
    <tr>
      <th colSpan="2" class="tablecenter"  style="text-align:center;">O/P Voltage (Volt)</th>
      <th colSpan="2" class="tablecenter"  style="text-align:center;">O/P Voltage (Volt)</th>
      <th colSpan="2" class="tablecenter"  style="text-align:center;">Voltage (Volt)</th>
    </tr>
    <tr>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">No Load</th>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">Full Load</th>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">No Load</th>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">Full Load</th>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">No Load</th>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">Full Load</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">R-Y</td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ry?.mainPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ry?.mainPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ry?.ACDBPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ry?.ACDBPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ry?.chargerIP?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ry?.chargerIP?.fullLoad || '-'}
      </td>
    </tr>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">Y-B</td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yb?.mainPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yb?.mainPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yb?.ACDBPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yb?.ACDBPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yb?.chargerIP?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yb?.chargerIP?.fullLoad || '-'}
      </td>
    </tr>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">B-R</td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.br?.mainPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.br?.mainPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.br?.ACDBPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.br?.ACDBPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.br?.chargerIP?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.br?.chargerIP?.fullLoad || '-'}
      </td>
    </tr>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">R-N</td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.rn?.mainPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.rn?.mainPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.rn?.ACDBPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.rn?.ACDBPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.rn?.chargerIP?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.rn?.chargerIP?.fullLoad || '-'}
      </td>
    </tr>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">Y-N</td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yn?.mainPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yn?.mainPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yn?.ACDBPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yn?.ACDBPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yn?.chargerIP?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.yn?.chargerIP?.fullLoad || '-'}
      </td>
    </tr>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">B-N</td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.bn?.mainPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.bn?.mainPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.bn?.ACDBPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.bn?.ACDBPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.bn?.chargerIP?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.bn?.chargerIP?.fullLoad || '-'}
      </td>
    </tr>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">N-E</td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ne?.mainPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ne?.mainPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ne?.ACDBPanel?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ne?.ACDBPanel?.fullLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ne?.chargerIP?.noLoad || '-'}
      </td>
      <td class="tablecenterp">${rowData?.powerMeasurement?.ne?.chargerIP?.fullLoad || '-'}
      </td>
    </tr>
  </tbody>
</table>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Earth Pit 1 Location</strong>
    <p class="tablep">${rowData?.powerMeasurement?.earthPit1Location || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Earth Pit 1 Earthing Resistance (Ohms)</strong>
    <p class="tablep">${rowData?.powerMeasurement?.earthPit1Resistance || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Earth Pit 2 Location</strong>
    <p class="tablep">${rowData?.powerMeasurement?.earthPit2Location || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Earth Pit 2 Earthing Resistance (Ohms)</strong>
    <p class="tablep">${rowData?.powerMeasurement?.earthPit2Resistance || '-'}</p>
  </div>
</div>
<div style="page-break-before:always">&nbsp;</div>
<table cellpadding="5" style="font-family: Mulish;width: 100%;border-radius:0px;">
  <thead>
    <tr>
      <th colSpan="1" rowSpan="2" class="tablecenter"  style="text-align:center;">
        Phase
      </th>
      <th colSpan="2" class="tablecenter"  style="text-align:center;">Current Measurement (Amp)</th>
      <th colSpan="2" class="tablecenter"  style="text-align:center;">Power Measurement (W)</th>
    </tr>
    <tr>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">Full Load</th>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">No Load</th>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">Full Load</th>
      <th colSpan="col" class="tablecenter"  style="text-align:center;">No Load</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">
        R
      </td>
      <td class="tablecenterp">
        ${rowData?.powerMeasurement?.phase?.r || '-'}
      </td>
      <td class="tablecenterp">
        ${rowData?.powerMeasurement?.phase?.bcmnl || '-'}
      </td>
      <td class="tablecenterp">
        ${(rowData?.powerMeasurement?.phase?.r * rowData?.powerMeasurement?.rn?.ACDBPanel?.fullLoad).toFixed(2) || '-'}
      </td>
      <td class="tablecenterp">
        ${(rowData?.powerMeasurement?.phase?.bcmnl * rowData?.powerMeasurement?.rn?.ACDBPanel?.noLoad).toFixed(2) || '-'}
      </td>
    </tr>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">
        Y
      </td>
      <td class="tablecenterp">
        ${rowData?.powerMeasurement?.phase?.y || '-'}
      </td>
      <td class="tablecenterp">
        ${rowData?.powerMeasurement?.phase?.ycmnl || '-'}
      </td>
      <td class="tablecenterp">
        ${(rowData?.powerMeasurement?.phase?.y * rowData?.powerMeasurement?.yn?.ACDBPanel?.fullLoad).toFixed(2) || '-'}
      </td>
      <td class="tablecenterp">
        ${(rowData?.powerMeasurement?.phase?.ycmnl * rowData?.powerMeasurement?.yn?.ACDBPanel?.noLoad).toFixed(2) || '-'}
      </td>
    </tr>
    <tr>
      <td style="text-align:center;font-weight:900;font-family: Mulish;font-size:12px;border-radius:0px">
        B
      </td>
      <td class="tablecenterp">
        ${rowData?.powerMeasurement?.phase?.b || '-'}
      </td>
      <td class="tablecenterp">
        ${rowData?.powerMeasurement?.phase?.bcmnl || '-'}
      </td>
      <td class="tablecenterp">
        ${(rowData?.powerMeasurement?.phase?.b * rowData?.powerMeasurement?.bn?.ACDBPanel?.fullLoad).toFixed(2) || '-'}
      </td>
      <td class="tablecenterp">
        ${(rowData?.powerMeasurement?.phase?.bcmnl * rowData?.powerMeasurement?.bn?.ACDBPanel?.noLoad).toFixed(2) || '-'}
      </td>
    </tr>
  </tbody>
</table>
 <div className="note_div">
  <h3 class="newp" style="text-decoration:underline;color: #1abc9c;text-transform: uppercase;">Note</h3>
  <ol style="font-family: Mulish;border-radius:0px;font-size: 12px;padding-left: 1em;">
    <li >Full Load: When all the Guns are in operations</li>
    <li>No Load Condition: Charging is not happening with any of the charger gun - idle condition</li>
  </ol>
</div>
<div style="margin:1em 0em 0em;"></div>
<h4 class="tableheadeing">CMS Integration Status & Functional Check Points</h4>
<div style="margin:0.1em 0em 0.3em;background:#1abc9c;height:1px;"></div>
<table cellpadding="5" style="font-family: Mulish;width: 100%;border: 1px solid #fefefe;border-radius:0px;">
  <tbody>
    <tr>
      <td class="newp"><span style="text-align:center;display:block">1</span></td>
      <td class="newp">ChargeZone OCPI ID & URL configured in charge?</td>
      <td style="font-family: Mulish;border-radius:0px">${rowData?.cmsIntegration?.OCPIConfigured || '-'}</td>
    </tr>
    <tr>
      <td class="newp"><span style="text-align:center;display:block">2</span></td>
      <td class="newp">Charger shows online with charge cloud On Charger Display?</td>
      <td style="font-family: Mulish;border-radius:0px">${rowData?.cmsIntegration?.chargerOnlineCloud || '-'}</td>
    </tr>
    <tr>
      <td class="newp"><span style="text-align:center;display:block">3</span></td>
      <td class="newp">Charger shown online on ChargeZone mobile app?</td>
      <td style="font-family: Mulish;border-radius:0px">${rowData?.cmsIntegration?.chargerOnlineMobile || '-'}</td>
    </tr>
  </tbody>
</table>
<div style="margin:0.3em 0em 0em;"></div>
<table cellpadding="5" style="font-family: Mulish;width: 100%;border-radius:0px;">
  <thead>
    <tr>
      <th rowSpan="2" class="tablecenter"  style="text-align:center;">Sr.No</th>
      <th colSpan="1" rowSpan="2" class="tablecenter"  style="text-align:center;">
        Operational Check Points
      </th>
      <th colSpan="2" class="tablecenter"  style="text-align:center;">On Charger Display</th>
      <th colSpan="2" class="tablecenter"  style="text-align:center;">On CMS</th>
    </tr>
    <tr>
      <th class="tablecenter"  style="text-align:center;">Actual</th>
      <th class="tablecenter"  style="text-align:center;">Expected</th>
      <th class="tablecenter"  style="text-align:center;">Actual</th>
      <th class="tablecenter"  style="text-align:center;">Expected</th>
    </tr>
    <tr>
      <th class="tablecenter"  style="text-align:center;">1</th>
      <th colSpan="6" class="tablecenter" style="text-align:left;">Power On the Charger</th>
    </tr>
  </thead>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">a</span></td>
    <td class="newp">Boot Notification</td>
    <td class="newp">NA</td>
    <td class="newp">NA</td>
    <td class="tablepara">${rowData?.cmsIntegration?.operationalCheck?.power?.bootNotification?.onCMS || '-'}</td>
    <td class="newp">YES</td>
  </tr>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">b</span></td>
    <td class="newp"> Available Status for each Gun - Status Notification Details</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.power?.gunAvailableStatus?.onCharger || '-'}
    </td>
    <td class="newp">YES</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.power?.gunAvailableStatus?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <thead>
    <tr>
      <th class="tablecenter"  style="text-align:center">2</th>
      <th colSpan="6" class="tablecenter" style="text-align:left;">Charge with OTP generated by CZ Mobile Application - Remote start from mobile application </th>
    </tr>
  </thead>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">a</span></td>
    <td class="newp">Authorize </td>
    <td class="newp">NA</td>
    <td class="newp">NA</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.remoteStart?.authorize?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">b</span></td>
    <td class="newp"> Status during various stages of charging </br>Preparing - Gun is connected to EV </br>Charging - During the Charging </br>Finishing - When charging stops </td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.otpStart?.status?.onCharger || '-'}
    </td>
    <td class="newp">YES</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.otpStart?.status?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <thead>
    <tr>
      <th class="tablecenter"  style="text-align:center">3</th>
      <th colSpan="6" class="tablecenter" style="text-align:left;">Press Emergency Stop Button on the charger</th>
    </tr>
  </thead>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">a</span></td>
    <td class="newp">Faulted status with relevant error message on charger display </td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.emergencyStop?.faultedStatus?.onCharger || '-'}
    </td>
    <td class="newp">YES</td>
    <td class="tablepara">
    ${rowData?.cmsIntegration?.operationalCheck?.emergencyStop?.faultedStatus?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">b</span></td>
    <td class="newp">Stop Reason as &apos;Emergency Stop &apos;in Stop Transaction</td>
    <td class="newp">NA</td>
    <td class="newp">NA</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.emergencyStop?.stopReason?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <thead>
    <tr>
      <th class="tablecenter"  style="text-align:center">4</th>
      <th colSpan="6" class="tablecenter" style="text-align:left;">Charge with OTP generated by CZ Mobile Application - Enter OTP on the charger display</th>
    </tr>
  </thead>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">a</span></td>
    <td class="newp">Charger display touch working, okay? </td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.otpStart?.displayWorking?.onCharger || '-'}
    </td>
    <td class="newp">YES</td>
    <td class="newp">NA</td>
    <td class="newp">NA</td>
  </tr>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">b</span></td>
    <td class="newp">Authorize</td>
    <td class="newp">NA</td>
    <td class="newp">NA</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.otpStart?.authorize?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">c</span></td>
    <td class="newp">
    Status during various stages of charging  <br />
    Preparing - Gun is connected to EV <br />
    Charging - During the Charging <br />
    Finishing - When charging stops
    </td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.otpStart?.status?.onCharger || '-'}
    </td>
    <td class="newp">YES</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.otpStart?.status?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <thead>
    <tr>
      <th class="tablecenter"  style="text-align:center">5</th>
      <th colSpan="6" class="tablecenter" style="text-align:left;">Simulate Power Failure</th>
    </tr>
  </thead>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">a</span></td>
    <td class="newp">Faulted status with relevant error message on charger display</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.powerFailure?.status?.onCharger || '-'}
    </td>
    <td class="newp">YES</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.powerFailure?.status?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">b</span></td>
    <td class="newp">Stop Reason as &apos;Power Failure&apos; in Stop Transaction</td>
    <td class="newp">NA</td>
    <td class="newp">NA</td>
    <td class="tablepara">
     ${rowData?.cmsIntegration?.operationalCheck?.powerFailure?.stopReason?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <thead>
    <tr>
      <th class="tablecenter"  style="text-align:center">6</th>
      <th colSpan="6" class="tablecenter" style="text-align:left;">Start Charging using registered RFID card</th>
    </tr>
  </thead>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">a</span></td>
    <td class="newp">RFID card is getting detected easily by the charge?</td>
    <td class="tablepara">
       ${rowData?.cmsIntegration?.operationalCheck?.rfid?.detection?.onCharger || '-'}
    </td>
    <td class="newp">YES</td>
    <td class="newp">NA</td>
    <td class="newp">NA</td>
  </tr>
  <thead>
    <tr>
      <th class="tablecenter"  style="text-align:center">7</th>
      <th colSpan="6" class="tablecenter" style="text-align:left;">Simulate Door Open during charging</th>
    </tr>
  </thead>
  <tr>
    <td class="newp"><span style="text-align:right;display:block">a</span></td>
    <td class="newp">Faulted status with relevant error message on charger display</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.doorOpen?.errorDisplay?.onCharger || '-'}
    </td>
    <td class="newp">YES</td>
    <td class="tablepara">
      ${rowData?.cmsIntegration?.operationalCheck?.doorOpen?.errorDisplay?.onCMS || '-'}
    </td>
    <td class="newp">YES</td>
  </tr>
  <tr>
    <td colSpan="1" class="newp"><span style="text-align:center;display:block">8</span></td>
    <td colSpan="3" class="newp">Remote start-stop from mobile application working okay?</td>
    <td colSpan="2" style="border-radius:0px;">
      ${rowData?.cmsIntegration?.operationalCheck?.remoteStartStopWorking || '-'}
    </td>
  </tr>
  <tr>
    <td colSpan="1" class="newp"><span style="text-align:center;display:block">9</span></td>
    <td colSpan="3" class="newp">All the above operations working okay with all the guns of charger?</td>
    <td colSpan="2" style="border-radius:0px;">
      ${rowData?.cmsIntegration?.operationalCheck?.allOperationWorkingForAllGuns || '-'}
    </td>
  </tr>
</table>
<div style="page-break-before:always">&nbsp;</div> 
<h4 class="tableheadeing">Internet Details</h4>
<div style="margin:0.1em 0em;background:#1abc9c;height:1px;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Network Connectivity Mode</strong>
    <p class="tablep">${rowData?.internetDetails?.networkConnectivityMode || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">Sim Card Details</strong>
    <p class="tablep">${rowData?.internetDetails?.simCardDetails || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Network Provider</strong>
    <p class="tablep">${rowData?.internetDetails?.simNetworkProvider || '-'}</p>
  </div>
  <div class="part-2" class="colStyle">
    <strong class="tablelabel">SIM / ICCID Card Number</strong>
    <p class="tablep">${rowData?.internetDetails?.simCardNumber || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Mobile Number</strong>
    <p class="tablep">${rowData?.internetDetails?.mobileNumber || '-'}</p>
  </div>
</div>
<h6 style="font-weight:900;color:#000;text-transform:uppercase;font-family: Mulish;margin-bottom:0px;">Wi-fi Connection Details</h6>
<div style="margin:0.1em 0em;background:#000;height:1px;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">SSID</strong>
    <p class="tablep">${rowData?.internetDetails?.ssid || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Password</strong>
    <p class="tablep">${rowData?.internetDetails?.password || '-'}</p>
  </div>
</div>
<h6 style="font-weight:900;color:#000;text-transform:uppercase;font-family: Mulish;margin-bottom:0px;">Ethernet / Leased Line Details</h6>
<div style="margin:0.1em 0em;background:#000;height:1px;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Network Provider</strong>
    <p class="tablep">${rowData?.internetDetails?.ethernetNetworkProvider || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">IPs</strong>
    <p class="tablep">${rowData?.internetDetails?.ips || '-'}</p>
    ${staticIpsHTML}
  </div>
</div>

<div style="margin:1em 0em 0em;"></div>
<h4 class="tableheadeing">Charger Efficiency</h4>
<div style="margin:0.1em 0em 0.3em;background:#1abc9c;height:1px;"></div>
<table cellpadding="5" style="font-family: Mulish;width: 100%;border-radius:0px;">
  <thead class="align-middle">
    <tr>
      <th rowSpan="2" scope="colgroup" class="newp ">
       <span style="text-align:center;display:block"> Sr.No.</span>
      </th>
      <th rowSpan="2" scope="colgroup" class="newp">
        <span style="text-align:center;display:block">Connector</span>
      </th>
      <th colSpan="2" scope="colgroup" class="newp">
        <span style="text-align:center;display:block">AC Energy <br />
        Meter Reading (kWh)</span>
      </th>
      <th rowSpan="2" scope="colgroup" class="newp">
        <span style="text-align:center;display:block">AC Energy <br />
        <span style="text-transform:none">(kWh)</span></span>
      </th>
      <th colSpan="2" class="newp"><span style="text-align:center;display:block">SoC (%)</span></th>
      <th rowSpan="2" class="newp">
        <span style="text-align:center;display:block">Max O/p <br /> Power <span style="text-transform: none" >(kW)</span></span>
      </th>
      <th colSpan="2" class="newp">
        <span style="text-align:center;display:block">DC Energy <br />
          <span style="text-transform: none" >(kWh)</span></span>
      </th>
      <th rowSpan="2" class="newp">
        <span style="text-align:center;display:block">Efficiency <br /> (%)</span>
      </th>
    </tr>
    <tr>
      <th class="newp"><span style="text-align:center;display:block">Start</span></th>
      <th class="newp"><span style="text-align:center;display:block">Stop</span></th>
      <th class="newp"><span style="text-align:center;display:block">Start</span></th>
      <th class="newp"><span style="text-align:center;display:block">Stop</span></th>
      <th class="newp"><span style="text-align:center;display:block">&nbsp;CMS &nbsp;</span></th>
      <th class="newp">
        <span style="text-align:center;display:block">Charger <br /> HMI</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="font-weight:900;font-family: Mulish;border-radius:0px;text-align:center">1</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.connector || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.acStart || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.acStop || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.acEnergy || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.socStart || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.socStop || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.maxPower || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.dcEnergy || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.dcCharger || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[1]?.efficiency || '-'}</td>
    </tr>
    <tr>
      <td style="font-weight:900;font-family: Mulish;border-radius:0px;text-align:center">2</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.connector || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.acStart || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.acStop || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.acEnergy || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.socStart || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.socStop || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.maxPower || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.dcEnergy || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.dcCharger || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[2]?.efficiency || '-'}</td>
    </tr>
    <tr>
      <td style="font-weight:900;font-family: Mulish;border-radius:0px;text-align:center">3</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.connector || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.acStart || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.acStop || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.acEnergy || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.socStart || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.socStop || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.maxPower || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.dcEnergy || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.dcCharger || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[3]?.efficiency || '-'}</td>
    </tr>
    <tr>
      <td style="font-weight:900;font-family: Mulish;border-radius:0px;text-align:center">4</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.connector || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.acStart || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.acStop || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.acEnergy || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.socStart || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.socStop || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.maxPower || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.dcEnergy || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.dcCharger || '-'}</td>
      <td class="tablepara">${rowData?.chargerEfficiency?.[4]?.efficiency || '-'}</td>
    </tr>
  </tbody>
</table>
<div class="note_div">
<h3 style="font-weight:900;color:#1abc9c;text-transform:uppercase;font-family: Mulish;margin-top:0.3em;margin-bottom:0px">FORMULA FOR THE CALCULATION</h3>
<p style="font-family: Mulish;">EFFICIENCY (%) = (CHARGER HMI / AC ENERGY) X 100</p>
<p style={{ textTransform: 'none'; margin:0; padding:0 }}>Optional* - Feasibility for the efficiency measurement for an AC Charger on site can vary from site to site based on the electrical infrastructure.</p>
</div>
<div style="margin:1em 0em 0em;"></div>
<h4 class="tableheadeing">Person of Contact</h4>
<div style="margin:0.1em 0em 0.3em;background:#1abc9c;height:1px;"></div>
<div class="div-2" class="tableStyle">
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Name</strong>
    <p class="tablep">${rowData?.personOfContact?.name || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Contact Number</strong>
    <p class="tablep">${rowData?.personOfContact?.contactNo || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Alternate Contact Details</strong>
    <p class="tablep">${rowData?.personOfContact?.alternateContact || '-'}</p>
  </div>
  <div class="part-1" class="colStyle">
    <strong class="tablelabel">Charger Key handover or not?</strong>
    <p class="tablep">${rowData?.personOfContact?.keyHandover || '-'}</p>
  </div>
</div>
<div class="signoff">
<h4 class="tableheadeing">Sign Off</h4>
<div style="margin:0.1em 0em 0.3em;background:#1abc9c;height:1px;"></div>
<table cellpadding="5" style="font-family: Mulish;width: 100%;border-radius:0px;">
  <tr>
    <td class="tablepara"></td>
    <td class="newp"><span style="text-align:center;display:block">CHARGEZONE</span></td>
    <td  class="newp"><span style="text-align:center;display:block">OEM</span></td>
    <td  class="newp"><span style="text-align:center;display:block">CLIENT</span></td>
  </tr>
  <tr>
    <td  class="newp">Commissioning Engineer Name</td>
    <td class="tablepara">${rowData?.signUp?.commissionEngineer?.chargeZone || '-'}</td>
    <td class="tablepara">${rowData?.signUp?.commissionEngineer?.oem || '-'}</td>
    <td class="tablepara">${rowData?.signUp?.commissionEngineer?.client || '-'}</td>
  </tr>
  <tr>
    <td  class="newp">Sign</td>
    <td class="tablepara"><img src="${rowData?.signUp?.sign?.chargeZone}" class="logoStyle"/></td>
    <td class="tablepara"><img src="${rowData?.signUp?.sign?.oem}" class="logoStyle"/></td>
    <td class="tablepara"><img src="${rowData?.signUp?.sign?.client}" class="logoStyle"/></td>
  </tr>
  <tr>
    <td class="newp">Date</td>
    <td class="tablepara">${rowData?.signUp?.date?.chargeZone || '-'}</td>
    <td class="tablepara">${rowData?.signUp?.date?.oem || '-'}</td>
    <td class="tablepara">${rowData?.signUp?.date?.client || '-'}</td>
  </tr>
</table>
</div>
<div style="margin:1em 0em 0em;"></div>
<h4 class="tableheadeing">Assignee Comments</h4>
<p class="tablep">${rowData?.assigneeComment || '-'}</p>
      </body>
    </html>
`;
  return getFormattedHTML();
};

// const downloadPDF = async (rowData) => {
//   const contentHTML = await generateContentHTML(rowData);
//   html2pdf()
//     .from(contentHTML)
//     .set({
//       margin: 0.5,
//       filename: `${rowData?.submittedBy.name}_${rowData?.submittedBy.lastName}.pdf`,
//       jsPDF: { orientation: 'p', unit: 'cm', format: 'a4', putOnlyUsedFonts: true, floatPrecision: 16 },
//       y: 0,
//       x: 0,
//       width: '100%',
//       height: '100%',
//       html2canvas: {
//         scale: 2,
//         dpi: 80,
//         letterRendering: true,
//         allowTaint: true,
//         useCORS: true,
//       },
//       pagebreak: { mode: ['css', 'legacy'] },
//     })
//     .toPdf()
//     .get('pdf')
//     .save();
// };
// const downloadPDF = (rowData) => {
//   const contentHTML = generateContentHTML(rowData);
//   html2pdf()
//     .from(contentHTML)
//     .set({
//       margin: 0.5,
//       filename: `abc.pdf`,
//       jsPDF: { orientation: 'p', unit: 'cm', format: 'a4', putOnlyUsedFonts: true, floatPrecision: 16 },
//       y: 0,
//       x: 0,
//       width: '100%',
//       height: '100%',
//       html2canvas: {
//         scale: 3,
//         dpi: 300,
//         letterRendering: true,
//         allowTaint: true,
//         useCORS: true,
//       },
//       pagebreak: { mode: ['css', 'legacy'] },
//     })
//     .toPdf()
//     .output('bloburl')
//     .then(function (url) {
//       window.open(url, '_blank'); // Open the PDF in a new tab
//     });
// };
const downloadPDF = async (rowData) => {
  const contentHTML = await generateContentHTML(rowData);
  const blob = new Blob([contentHTML], { type: 'text/html' });
  const url = URL.createObjectURL(blob);
  const newTab = window.open(url, '_blank');
  if (newTab) {
    newTab.onload = () => {
      const filename = `${rowData?.documentNo}`;
      newTab.document.title = filename;
      setTimeout(() => {
        newTab.print();
      }, 500); // 1 second delay
    };
  } else {
    console.error('Failed to open new tab. Please check if pop-ups are blocked.');
  }
};

export default downloadPDF;
