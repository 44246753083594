import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import FormikTextInput from 'components/inputs/FormikInputs';
import { changeStep, saveForm, updateForm } from 'actions/formActions';
import RadioButton from 'components/inputs/RadioButton';

const defaultValues = {
  electricityConnectionName: '',
  partnerContactNumber: '',
  applicationNumber: '',
  consumerName: '',
  consumerContact: '',
  contractorName: '',
  contractorContact: '',
  sanctionedLoad: '',
  electricityBillPayer: '',
  powerAvailabilityDate: '',
  docoConsumerName: '',
  docoConsumerEmail: '',
};

const Details = ({ currentStep }) => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state.forms);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [checked, setChecked] = useState('no');
  const [consumerType, setConsumerType] = useState('COCO'); // Default is COCO

  useEffect(() => {
    if (form !== null) {
      setInitialValues({
        electricityConnectionName: form.electricityConnectionName,
        partnerContactNumber: form.partnerContactNumber,
        applicationNumber: form.applicationNumber,
        consumerName: form.consumerName,
        consumerContact: form.consumerContact,
        contractorName: form.contractorName,
        contractorContact: form.contractorContact,
        sanctionedLoad: form.sanctionedLoad,
        electricityBillPayer: form.electricityBillPayer,
        powerAvailabilityDate: form.powerAvailabilityDate || new Date().toISOString().slice(0, 10),
        docoConsumerName: form.docoConsumerName || '',
        docoConsumerEmail: form.docoConsumerEmail || '',
      });
      if (form?.existconnection) {
        setChecked(form?.existconnection);
      }
      if (form?.consumerType) {
        setConsumerType(form?.consumerType);
      }
    } else {
      setInitialValues({
        ...defaultValues,
        powerAvailabilityDate: new Date().toISOString().slice(0, 10),
      });
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.step = (currentStep + 1).toString();
      values.existconnection = checked;
      values.consumerType = consumerType;
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        dispatch(updateForm(form?.id, values));
      } else {
        dispatch(saveForm(values));
      }
    },
  });

  const handleConnectionChange = (e) => {
    setChecked(e.target.value);
  };

  const handleConsumerTypeChange = (e) => {
    setConsumerType(e.target.value);
  };

  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        {/* Existing fields */}
        <div className="row mt-3">
          <div className="col-4">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Electricity Connection Ownership Name"
                placeholder="Enter Electricity Connection Ownership Name"
                isRequired
                name="electricityConnectionName"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Electricity Connection Ownership Number"
                placeholder="Enter Electricity Connection Ownership Number"
                isRequired
                name="partnerContactNumber"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Electricity Connection Application Number"
                placeholder="Enter Electricity Connection Application Number"
                isRequired
                name="applicationNumber"
                formik={formik}
              />
            </div>
          </div>
        </div>

        {/* Customer Name and Number as per Electricity Bill */}
        <div className="row my-3">
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Consumer Name as per Electricity Bill"
                placeholder="Enter Consumer Name as per Electricity Bill"
                isRequired
                name="consumerName"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Consumer Number as per Electricity Bill"
                placeholder="Enter Consumer Number as per Electricity Bill"
                isRequired
                name="consumerContact"
                formik={formik}
              />
            </div>
          </div>
        </div>

        {/* Consumer Type Radio Buttons */}
        <div className="row my-3">
          <div className="col-12">
            <div className="mb-3">
              <div className="d-flex gap-2 align-items-center">
                <FormLabel htmlFor="consumerType" className="form-label">Consumer Type</FormLabel>
                <div className="d-flex gap-3">
                  <RadioButton
                    id="consumerType_doco"
                    containerClassName="radio-field"
                    value="DOCO"
                    checked={consumerType === 'DOCO'}
                    onChange={handleConsumerTypeChange}
                  >
                    DOCO
                  </RadioButton>
                  <RadioButton
                    id="consumerType_coco"
                    containerClassName="radio-field"
                    value="COCO"
                    checked={consumerType === 'COCO'}
                    onChange={handleConsumerTypeChange}
                  >
                    COCO
                  </RadioButton>
                  <RadioButton
                    id="consumerType_other"
                    containerClassName="radio-field"
                    value="Other"
                    checked={consumerType === 'Other'}
                    onChange={handleConsumerTypeChange}
                  >
                    Other
                  </RadioButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Show additional fields if DOCO is selected */}
        {consumerType === 'DOCO' && (
          <div className="row my-3">
            <div className="col-6">
              <div className="mb-3">
                <FormikTextInput
                  type="text"
                  label="DOCO Consumer Name"
                  placeholder="Enter DOCO Consumer Name"
                  className="text-input"
                  isRequired
                  name="docoConsumerName"
                  formik={formik}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <FormikTextInput
                  type="email"
                  label="DOCO Consumer Email"
                  placeholder="Enter DOCO Consumer Email"
                  className="text-input"
                  isRequired
                  name="docoConsumerEmail"
                  formik={formik}
                />
              </div>
            </div>
          </div>
        )}

        {/* Existing Connection Radio Buttons */}
        <div className="row my-3">
          <div className="col-12">
            <div className="mb-3">
              <div className="d-flex gap-2 align-items-center">
                <FormLabel htmlFor="storage" className="form-label">Existing Connection</FormLabel>
                <div className="d-flex gap-3">
                  <RadioButton
                    id="existconnection_yes"
                    containerClassName="radio-field"
                    value="yes"
                    checked={checked === 'yes'}
                    onChange={handleConnectionChange}
                  >
                    Yes
                  </RadioButton>
                  <RadioButton
                    id="existconnection_no"
                    containerClassName="radio-field"
                    value="no"
                    checked={checked === 'no'}
                    onChange={handleConnectionChange}
                  >
                    No
                  </RadioButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Conditionally render existing connection fields */}
        {checked === 'no' && (
          <div className="row my-3">
            <div className="col-6">
              <div className="mb-3">
                <FormikTextInput
                  type="text"
                  label="Responsible Class A Contractor Name"
                  placeholder="Enter Responsible Class A Contractor Name"
                  className="text-input"
                  isRequired
                  name="contractorName"
                  formik={formik}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <FormikTextInput
                  type="text"
                  label="Responsible Class A Contractor Contact Number"
                  placeholder="Enter Responsible Class A Contractor Contact Number"
                  className="text-input"
                  isRequired
                  name="contractorContact"
                  formik={formik}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <FormikTextInput
                  type="text"
                  label="Sanctioned Load (kW / kVA)"
                  placeholder="Enter Sanctioned Load (kW / kVA)"
                  className="text-input"
                  isRequired
                  name="sanctionedLoad"
                  formik={formik}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <FormikTextInput
                  type="text"
                  label="Electricity Bill Payer"
                  placeholder="Enter Electricity Bill Payer"
                  className="text-input"
                  isRequired
                  name="electricityBillPayer"
                  formik={formik}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <FormikTextInput
                  type="date"
                  className="text-input"
                  label="Power Availability Date"
                  placeholder="Enter Power Availability Date"
                  isRequired
                  name="powerAvailabilityDate"
                  formik={formik}
                />
              </div>
            </div>
          </div>
        )}

        {/* Navigation Buttons */}
        <div className="row my-3">
          <div className="buttons">
            <button
              type="button"
              className="prev-btn"
              onClick={() => {
                dispatch(changeStep(currentStep - 1));
              }}
            >
              Previous
            </button>
            {form?.status !== 'approved' && form?.status !== 'pending' ? (
              <button type="submit" className="submit-btn">
                Next
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  dispatch(changeStep(currentStep + 1));
                }}
                className="submit-btn"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

Details.propTypes = {
  currentStep: PropTypes.number,
};

export default Details;
